<template>
  <ValidationObserver ref="observer" tag="div" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validate)">
      <div class="w-full">
        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseInput type="text" label="Title" name="title" v-model="payload.title" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

        <div class="mb-2">
          <div class="mb-1"><label for="text" class="text-gray-500"> Relevance </label><button type="button" class="rounded px-1 pt-0 border" @click="addRelevance">+</button></div>

          <div class="relative mb-1" v-for="(relavance, i) in payload.relevances" :key="i">
            <input type="text" name="relavance[]" v-model="relavance.text" id="" class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500" />

            <font-awesome-icon icon="times" class=" text-red-500 absolute right-3 top-3" @click="removeRelavance(i)" />
          </div>
          
        </div>

        <div class="mb-2">
          <div class="mb-1"><label for="text" class="text-gray-500"> Delivery </label><button type="button" class="rounded px-1 pt-0 border" @click="addDelivery">+</button></div>

          <div class="relative mb-1" v-for="(delivery, i) in payload.deliveries" :key="i">
            <input type="text" name="deliveries[]" v-model="delivery.text" id="" class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500" />

            <font-awesome-icon icon="times" class=" text-red-500 absolute right-3 top-3"  @click="removeDelivery(i)" />
          </div>
          
        </div>

        <label for="text" class="text-gray-500"> Notes </label>
        <ValidationProvider v-slot="{ errors }" tag="div" rules="" class="mb-2 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500">
          <div v-if="editor">
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">bold</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">italic</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">strike</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">code</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().unsetAllMarks().run()">clear marks</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().clearNodes().run()">clear nodes</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">paragraph</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">h1</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">h2</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">h3</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">h4</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">h5</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">h6</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">bullet list</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">ordered list</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">blockquote</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().setHorizontalRule().run()">horizontal rule</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().setHardBreak().run()">hard break</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().undo().run()">undo</button>
            <button type="button" class="tiptap-button" @click="editor.chain().focus().redo().run()">redo</button>
          </div>

          <editor-content name="text" v-model="payload.text" :editor="editor" class="" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" tag="div" rules="" class="mb-2">
          <BaseInput type="number" label="Sort order" name="sort_order" v-model="payload.sort_order" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>

      <BaseBtn type="submit" :text="payload.id ? 'Update' : 'Add'" />
      <FlashMessage :message="message" :error="error" />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

extend("required", {
  ...required,
  message: "This field is required",
});

import { mapGetters } from "vuex";
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import BaseInput from "@/components/BaseInput";
import SlideService from "@/services/SlideService";
import FlashMessage from "@/components/FlashMessage";
import BaseSelect from "@/components/BaseSelect";
import Fabric from "@/components/Fabric";

library.add(faTimes);

export default {
  name: "Slide",
  components: {
    BaseBtn,
    BaseInput,
    BaseSelect,
    FlashMessage,
    Fabric,
    Editor,
    EditorContent,
    ValidationProvider,
    ValidationObserver,
    FontAwesomeIcon
  },
  props: {
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      payload: {
        title: "",
        text: "",
        content: "",
        sort_order: 0,
        relevances: [],
      deliveries: []
      },
      error: null,
      message: null,
      editor: null,
      
    };
  },
  computed: {
    ...mapGetters("slide", ["add", "update"]),
  },
  methods: {
    addRelevance() {
      this.payload.relevances.push({text: ""})
    },
    removeRelavance(index) {
      this.payload.relevances.splice(index, 1);
    },
    addDelivery() {
      this.payload.deliveries.push({text: ""})
    },
    removeDelivery(index) {
      this.payload.deliveries.splice(index, 1);
    },
    validate() {
      this.error = null;
      this.message = null;

      this.payload.text = this.editor.getHTML();

      console.log("this.payload", this.payload);

      if (this.payload.id) {
        SlideService.update(this.payload)
          .then(() => {
            this.message = "Slide updated.";
            this.$parent.close();
            this.$eventBus.$emit("slide:updated", true);
          })
          .catch((error) => (this.error = getError(error)));
      } else {
        SlideService.add(this.payload)
          .then(() => {
            this.message = "Slide added.";
            this.$parent.close();
            this.$eventBus.$emit("slide:added", true);
          })
          .catch((error) => (this.error = getError(error)));
      }
    },
  },
  mounted() {
    if (this.attributes.payload) {
      this.payload = this.attributes.payload;
    }

    if (this.attributes.concept_id) {
      this.payload.concept_id = this.attributes.concept_id;
    }

    this.editor = new Editor({
      content: this.payload.text,
      extensions: [StarterKit],
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
<style lang="css">
/* Basic editor styles */
.ProseMirror {
  min-height: 100px;
  max-height: 100px;
  padding: 10px;
  overflow: scroll;
}

.ProseMirror * + * {
  margin-top: 0.75em;
}

.ProseMirror ul {
  padding: 0 1rem;
  list-style: disc;
}

.ProseMirror ol {
  padding: 0 1rem;
  list-style: decimal;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0d0d0d, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(#0d0d0d, 0.1);
  margin: 2rem 0;
}
</style>